@import "variables";

.login-logo {
    .logo {
        img {
    
            width: 120px;
            height: auto;
        }
    }
    .logo-small {
        width: auto;
        img {
            height: auto;
            width: 60px;
        }
    }
}

.logo-header {
    
    .logo {

        img {
            height: auto;
            width: 120px;
        }

        .navbar-brand-minimized{
            margin-left: 5px;
            width: 44px;
        }

    }
    .logo-small {
        width: auto;
        img {
            height: auto;
            width: 50px;
        }
    }

}

@media (max-width: $xs) {
    .login-logo {
        .logo-small {
            
            width: auto;

            img {
                height: auto;
                width: 40px;
            }
        }
    }
    .logo-header {
        .logo-small {
            width: auto;
        }
    }
}
@media (max-width: $sm) {
    .logo-header {
        .logo-small.navbar-brand {
            width: auto;
            img {
                height: auto;
                width: 55px;
            }
        }
    }
}