@import "../variables";

.login-container {

    .login-headline-container {
        height: 100%;
        position: relative;

        .login-headline {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
        }
    }

    .dropdown-container {

        height: 100%;
        position: relative;
        display: block;
        padding: 0;

        .global-dropdown {
            text-decoration: none;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
        }
    }
}

@media (max-width: $sm) {
    .login-container {
        .login-card {
            margin-right: -15px;
            margin-left: -15px;

            .logo.navbar-brand img {
                width: 30px;
            }

            .login-headline {
                font-size: 1.6rem;
            }

            .global-dropdown {
                .nav-link {
                    padding: 0;
                    padding-top: 10px;

                    i {
                        font-size: 1rem !important;
                        width: 15px;
                        height: auto;
                    }
                }
            }
        }
    }
}
