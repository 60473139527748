@import "../variables";

.workspace-list-wrapper {
    .workspace-list-headline {
        margin-top: 15px;
        margin-left: 15px;
        font-size: 20px;
    }
    .workspace-list {
        button {
            transition: 0.3s ease;
            border-radius: 0;
            border-bottom: none;
            border-left: none;
            border-right: none;
            border-top: 1px solid rgba(0, 0, 0, 0.125);

            &:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.05);
            }
            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            &:hover {
                background-color: rgba(32, 168, 216, 0.5);
                color: white;
                font-weight: bold;
                transition: 0.3s ease;
            }

            &.active {
                font-weight: bold;
                background-color: $color-primary;
            }
        }
    }
}
