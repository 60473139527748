// Variable overrides

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

//colors
$color-primary: #321fdb;
$color-secondary: #c8ced3;
$color-success: #4dbd74;
$color-danger: #f86c6b;
$color-warning: #ffc107;
$color-info: #63c2de;
$color-light: #f0f3f5;
$color-dark: #2f353a;

//dark-theme
$color-primary__dt: #e1e1e1;
$color-secondary__dt: #c8ced3;
$color-success__dt: #4dbd74;
$color-danger__dt: #f86c6b;
$color-warning__dt: #ffc107;
$color-info__dt: #63c2de;
$color-light__dt: #f0f3f5;
$color-dark__dt: #2f353a;
$color-card__dt: #23242d;
