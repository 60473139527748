.toast-container {

    min-height: 64px;
    height: 64px;
    width: 100%;
    position: relative;
    transition: background-color .3s ease;

    .toast-content {
        margin: 5px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%)
    }

    &:hover {
        transition: background-color .3s ease;
        background-color: #f8cb00;
    }
}
