.selection_buttons_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .selection_button {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    .btn-dark {
        color: #fff;
        background-color: #20202a;
        border-color: #20202a;

        &:hover {
            color: #fff;
            background-color: #0f1014;
            border-color: #0a0a0d;
        }
        &:active {
            color: #fff;
            background-color: #0a0a0d;
            border-color: #040406;
        }
    }

    .btn-active-light {
        color: #4f5d73;
        background-color: #d1dbe1;
        border: 0;
        box-shadow: 0 4px 5px 0 rgba(60, 75, 100, 0.14),
            0 1px 10px 0 rgba(60, 75, 100, 0.12),
            0 2px 4px -1px rgba(60, 75, 100, 0.2);
    }

    .btn-active-dark {
        color: #fff;
        background-color: #181b1e;
        border-color: #121517;
        box-shadow: 0 0 0 0.2rem rgba(78, 83, 88, 0.5);
    }
}
