@import "../variables";

.default-table {
    font-size: 14px;
}
.table th,
.table td {
    vertical-align: middle !important;
}

.empty-table {
    .message,
    .content {
        font-size: 16px;
    }

    .item {
        font-size: 100px;
        display: block;
        position: relative;
        border: none;
        text-align: center;

        .content {
            padding: 15px;
            transition: 0.3s ease;

            & i {
                width: 100%;
                transition: 0.3s ease;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                    transition: 0.3s;
                }
            }

            a {
                width: 100%;
                height: auto;
            }

            p {
                font-size: 18px;
            }
        }
    }
}
