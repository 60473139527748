@import "../variables";

.react-bootstrap-table table {
    table-layout: auto;
    position: relative;
}


.table-header-column {
    width: 30%;

    &>* {
        width: 30%;
    }
}

.react-bs-table-container {

    .react-bs-table table td,
    .react-bs-table table th {
        text-overflow: unset;
    }
}

.table-container {
    position: relative;

    .empty-table {
        margin-top: 30px;
        display: block;
        position: absolute;
        height: 35px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.default-table {

    .table img {
            max-width: 16px;
            max-height: 16px;
        }

    .search-label {
        display: block;
    }

    .empty-table {
        .message {
            font-size: 16px;
        }

        .item {
            font-size: 100px;
            display: block;
            position: relative;
            text-align: center;

            .content {
                padding: 15px;
                transition: .3s ease;

                & i {
                    transition: .3s ease;
                    cursor: pointer;

                    &:hover {
                        padding: 15px;
                        opacity: .9;
                        color: $color-secondary;
                        transition: .3s ease;
                    }
                }

                a {
                    width: 100%;
                    height: auto;
                }

                p {
                    font-size: 18px;
                }

            }
        }
    }
}



@media (max-width: $sm) {
    .table-card {
        margin-left: -30px;
        margin-right: -30px;
    }
}
