// Here you can add other styles
@import "./custom/colors.scss";
@import "./custom/spinner.scss";
@import "./custom/aside.scss";
@import "./custom/table.scss";
@import "./custom/modal.scss";
@import "./custom/codeMirror.scss";
@import "./custom/_collapse.scss";
@import "./custom/_form.scss";
@import "./custom/accordion";
@import "./custom/selectionButtons";
@import "./custom/loaderWrapper";
@import "./custom/paginationBar";
.c-pointer {
    cursor: pointer;
}

.icon-button {
    cursor: pointer;
}
.table-icon {
    width: 1% !important;
}

.selection {
    z-index: 4;
}

.selection.small {
    font-size: 85%;
    [class$="indicatorContainer"] {
        padding: 2px;
    }
}

.progress-wrapper {
    box-sizing: content-box;
    min-width: 100px;
    border-radius: 25px;
    background-color: #768192;
    height: 25px;

    .progress-bar {
        height: 25px;
        border-radius: 25px;
    }
}
.toastItem {
    .toastItem__title {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .toastItem__detail-btn {
        margin-top: 10px;
        color: white;
        background-color: transparent;
        border: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
