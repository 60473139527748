// Variable overrides

//Breakpoints
$xs : 576px;
$sm : 768px;
$md : 992px;
$lg : 1200px;

//colors
$color-primary: #20a8d8;
$color-secondary: #c8ced3;
$color-success: #4dbd74;
$color-danger: #f86c6b;
$color-warning: #ffc107;
$color-info: #63c2de;
$color-light: #f0f3f5;
$color-dark: #2f353a;
