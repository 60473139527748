.pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pagination-dark {
        .MuiButtonBase-root {
            color: #e1e1e1 !important;
        }
        .MuiPaginationItem-ellipsis {
            color: #e1e1e1 !important;
        }
        .Mui-selected {
            background-color: rgba(255, 255, 255, 0.2) !important;
            &:hover {
                background-color: rgba(255, 255, 255, 0.25) !important;
            }
        }
    }
}
.pagination-headline {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
}
