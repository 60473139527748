.right-sidebar {
    overflow-y: scroll;

    .workspace-list-aside {
        .list-group {
            margin-left: -0.5rem;
            margin-right: -0.5rem;

            .list-item {
                border-radius: 0;
            }
        }
    }
}
