.spinner {
    height: 100vh !important;
}

.aut-loading-wrapper {
    background-color: transparent;
    position: relative;
    transition: 0.3 ease;
    height: fit-content;
    width: 100%;

    .overlay {
        background-color: transparent;
        opacity: 1;
        transition: 0.3s ease;
        &.overlay--loading {
            transition: 0.3s ease;
            opacity: 0.3;
            z-index: 100;
        }
    }
}
.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
