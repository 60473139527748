@import "../variables";

.custom-dropzone {
    .dzu-dropzone {

        overflow: hidden;
        border-style: dashed;

        .preview {
            display: block;
            width: 100%;
            padding: 15px;

            .success-response {
                font-size: 6rem;
            }

        }
    }
}
