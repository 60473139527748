@import "../_variables.scss";
.custom-collapse {
    .custom-collapse__button {
        width: 100%;
    }
    .custom-collapse__collapse {
        padding: 5px;
        padding-bottom: 15px;
        padding-top: 15px;
        margin-top: -1rem;
        border: 1px $color-secondary solid;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top: none;
        margin-bottom: 15px;
    }
}
