@import "../_variables.scss";

.accordion-dark {
    .MuiIconButton-root {
        color: #e1e1e1 !important;
    }
    background-color: #24252f !important;
    color: #e1e1e1 !important;
}
.MuiAccordionDetails-root {
    flex-direction: column;
}

.display-block {
    display: block !important;
}
