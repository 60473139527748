@import "../_variables.scss";
.form {
    .colorPicker {
        .colorPicker__label {
            margin-bottom: 15px;
        }
        .colorPicker__picker {
            border: 1px solid $color-secondary !important;
            box-shadow: none !important;

            .hue-horizontal {
                // display: none;
            }
        }
    }
}
