@import "../variables";

.prim-button {
    color: #20a8d8;

    &:hover {
        color: #2f353a;
    }
}

.color-success {
    color: $success;
}

.color-error {
    color: $danger;
}

.icon-check {
    color: $success;
}

.icon-warning, .color-warning {
    color: $color-warning
}

.icon-failed, .color-failed {
    color: $danger;
}

.z-index-1000 {
    z-index: 1000;
}

.max-width-80 {
    max-width: 80% !important;
}

.max-width-60 {
    max-width: 60% !important;
}

@media (max-width: $sm) {
    .res-sm-margin-15 {
        margin-right: -15px;
        margin-left: -15px;
    }

    .res-sm-margin-30 {
        margin-right: -30px;
        margin-left: -30px;
    }
}
