@import "../variables.scss";

.autlay-fold-cube-wrapper {
    .autlay-fold-cube {
        transform: rotateZ(0deg);

        .autlay-fold-item-primary {
            &::before {
                background-color: #e19346;
            }
        }
        .autlay-fold-item-secondary {
            &::before {
                background-color: $color-secondary;
            }
        }
        .autlay-fold-item {
            opacity: 0.9;
        }
    }
}
