@import "../variables";

.delete-button {
    padding: 0;
    background: transparent;
    border-color: transparent;
    transition: .25s ease;
    font-size: 16px;

    &:hover,
    &.disabled,
    &:focus {
        background: transparent;
        border-color: transparent;
        transition: .25s ease;
    }

    &:hover {
        color: $color-danger;
    }

}

.no-button-primary {
    padding: 0;
    color: $color-primary;
    background: transparent;
    border-color: transparent;
    border: none !important;
    transition: .25s ease;

    &:hover {
        color: $color-info;
        background: transparent;
        border: none !important;
        transition: .25s ease;
    }
}
